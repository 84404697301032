import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Inner from '../../components/templates/Inner';
import { Divider, Box, Flex, Text } from '../../components/atoms';
import FootNote from '../../components/molecules/FootNote';
import { designTips as nav } from '../../nav';
import img01 from '../../assets/img/design-tips/colors/01.png';
import img02 from '../../assets/img/design-tips/colors/02.png';
import img03 from '../../assets/img/design-tips/colors/03.png';
import img04 from '../../assets/img/design-tips/colors/04.png';
import img05 from '../../assets/img/design-tips/colors/05.png';
import img06 from '../../assets/img/design-tips/colors/06.png';
import img07 from '../../assets/img/design-tips/colors/07.png';
import img08 from '../../assets/img/design-tips/colors/08.png';
import img09 from '../../assets/img/design-tips/colors/09.png';
import img10 from '../../assets/img/design-tips/colors/10.png';
import img11 from '../../assets/img/design-tips/colors/11.png';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Inner nav={nav} title="Colors" mdxType="Inner">
      <h3>{`Colors`}</h3>
  <Text maxWidth={560} mdxType="Text">
    Consistent color usage boosts the brand recognition and conveys the right mood.
    It helps to guide users through the interface and highlight important areas.
  </Text>
  <Divider my={64} mdxType="Divider" />
      <h4>{`Categorize and follow`}</h4>
      <p>{`  Categorize the colors and share the exact palette you’ve documented with the team.`}</p>
      <h5>{`Guideline colors`}</h5>
  <Box maxWidth={688} mdxType="Box">
    <img src={img01} alt="" className="img-fluid" />
  </Box>
  <Flex my="xxl" mdxType="Flex">
    <Box width={320} mr={152} mdxType="Box">
      <Box mdxType="Box"><img src={img03} alt="" className="img-fluid" /></Box>
      <FootNote status="good" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">
        1. Brand color applied for secondary CTA<br />
        2. Minimum colors used.
      </Box>
    </Box>
    <Box width={320} mdxType="Box">
      <Box mdxType="Box"><img src={img02} alt="" className="img-fluid" /></Box>
      <FootNote status="bad" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">
        1. Bad choice of color for CTA headline.<br />
        2. “Download” button uses the color that is not in the guidelines.
      </Box>
    </Box>
  </Flex>
  <Divider my={64} mdxType="Divider" />
      <h4>{`Contrast = visually different`}</h4>
      <p>{`  Ensure a proper contract between colors. It is hard to navigate when colors blend into one.`}</p>
  <Flex my="xxl" mdxType="Flex">
    <Box width={320} mr={152} mdxType="Box">
      <Box mdxType="Box"><img src={img05} alt="" className="img-fluid" /></Box>
      <FootNote status="good" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">Good color combination</Box>
    </Box>
    <Box width={320} mdxType="Box">
      <Box mdxType="Box"><img src={img04} alt="" className="img-fluid" /></Box>
      <FootNote status="bad" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">Bad color combination</Box>
    </Box>
  </Flex>
  <Flex my="xxl" mdxType="Flex">
    <Box width={320} mr={152} mdxType="Box">
      <Box mdxType="Box"><img src={img07} alt="" className="img-fluid" /></Box>
      <FootNote status="good" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">Create color combination by using multiple shades of one color</Box>
    </Box>
    <Box width={320} mdxType="Box">
      <Box mdxType="Box"><img src={img06} alt="" className="img-fluid" /></Box>
      <FootNote status="bad" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">Bad color contrast</Box>
    </Box>
  </Flex>
  <Divider my={60} mdxType="Divider" />
      <h4>{`Make CTA stand out.`}</h4>
      <p>{`  Use separate color for the component that contains a clear call to action.`}</p>
  <Flex my="xxl" mdxType="Flex">
    <Box width={384} mr={152} mdxType="Box">
      <Box mdxType="Box"><img src={img09} alt="" className="img-fluid" /></Box>
      <FootNote status="good" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">Button stands out, call to action is clear.</Box>
    </Box>
    <Box width={384} mdxType="Box">
      <Box mdxType="Box"><img src={img08} alt="" className="img-fluid" /></Box>
      <FootNote status="bad" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">CTA button doesn’t stand out from the background, focus lost.</Box>
    </Box>
  </Flex>
  <Flex mt="xxl" mdxType="Flex">
    <Box width={320} mr={208} mdxType="Box">
      <Box mdxType="Box"><img src={img11} alt="" className="img-fluid" /></Box>
      <FootNote status="good" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">Emphasized call to action. Easier decision for the user.</Box>
    </Box>
    <Box width={320} mdxType="Box">
      <Box mdxType="Box"><img src={img10} alt="" className="img-fluid" /></Box>
      <FootNote status="bad" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">Two CTA buttons, focus lost.</Box>
    </Box>
  </Flex>
    </Inner>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      